// Load Bootstrap JS
import { Offcanvas } from 'bootstrap';

// Load Styles
import '../scss/main.scss';

// Load AOS
import Aos from "aos";
Aos.init();
import simpleParallax from 'simple-parallax-js';

import Vivus from 'vivus';

new Vivus('my-svg', 
    {
        file: '/wp-content/themes/departs.jp/dist/images/common/logo-vivus.svg', 
        type: 'sync', 
        duration: 200, 
        start:'autostart',
        onReady: function(){}
    }, 
    function(){
        document.getElementById('__D').style.stroke = 'none';
        document.getElementById('__D').style.fill = '#FFFFFF';
        document.getElementById('__DOOR').style.stroke = 'none';
        document.getElementById('__DOOR').style.fill = '#C7AC6B';
        document.getElementById('__E').style.stroke = 'none';
        document.getElementById('__E').style.fill = '#FFFFFF';
        document.getElementById('__P').style.stroke = 'none';
        document.getElementById('__P').style.fill = '#FFFFFF';
        document.getElementById('__A').style.stroke = 'none';
        document.getElementById('__A').style.fill = '#FFFFFF';
        document.getElementById('__R').style.stroke = 'none';
        document.getElementById('__R').style.fill = '#FFFFFF';
        document.getElementById('__T').style.stroke = 'none';
        document.getElementById('__T').style.fill = '#FFFFFF';
        document.getElementById('__S').style.stroke = 'none';
        document.getElementById('__S').style.fill = '#FFFFFF';
    }
);


var splax_images = document.getElementsByClassName('splax-image');
var splax_images_wide = document.getElementsByClassName('splax-image-wide');
new simpleParallax(splax_images, {overflow: true});
new simpleParallax(splax_images_wide, {orientation: 'right', overflow: true});

// App code
var hamburgerOpen = false;
const Hamburger = document.querySelector('#hamburger');
const OffcanvasMenu = document.querySelector('#offcanvasMenu');
var bsOffcanvas = new Offcanvas(OffcanvasMenu);
const Navbar = document.querySelector('#navbar');

OffcanvasMenu.addEventListener('hide.bs.offcanvas', function () {
  Hamburger.classList.remove('is-active');
  hamburgerOpen = false;
});
OffcanvasMenu.addEventListener('show.bs.offcanvas', function () {
  Hamburger.classList.add('is-active');
  hamburgerOpen = true;
});

document.querySelectorAll('.menu-item').forEach(function (button) {
    button.addEventListener('click', function(){
        bsOffcanvas.hide();
    });
});

var limit_y = document.documentElement.clientWidth;
window.addEventListener('resize', function(){
    limit_y = document.documentElement.clientWidth;
});

window.addEventListener('scroll', function(instance) {
    
    var _y = window.pageYOffset;
    var progress = 90 * _y / limit_y;
    var bgColor = `hsl(${progress}, 10%, 80%)`;

    document.body.style.backgroundColor = bgColor;
    Navbar.style.backgroundColor = bgColor;
    OffcanvasMenu.style.backgroundColor = bgColor;

    if(Navbar){
        if(_y < 10) { 
            Navbar.style.backgroundColor = 'transparent';
        }
    }  
}, {passive: true});
